import(/* webpackMode: "eager" */ "/vercel/path0/frontend/styles/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@rainbow-me/rainbowkit/dist/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
